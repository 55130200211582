.program-create-popup {
  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    & label {
      font-size: 13px;
      margin-bottom: 5px;
      color: rgba(32, 37, 49, 1);
    }

    & textarea {
      resize: none;
      height: 100px;
    }
  }

  &__date-wrapper {
    display: flex;
  }

  &__times-wrapper {
    display: flex;
    & .program-create-popup__field {
      margin-right: 20px;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__buttons {
    display: flex;
    margin-top: 20px;
    & button {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
