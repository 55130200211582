.events-popup {
  position: absolute;
  right: 0;
  width: 469px;
  background-color: #f7f8fc;
  // padding: 27px 32px;
  z-index: 444;
  height: calc(100vh - 64px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 720px) {
    height: calc(100vh - 130px);
  }
  @media (max-width: 650px) {
    width: 100vw;
  }

  &__header {
    padding: 27px 16px 16px 32px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 453px;
    z-index: 11;
    background-color: inherit;
    & h2 {
      font-size: 24px;
      font-weight: 500;
    }
    @media (max-width: 650px) {
      width: calc(100vw - 12px);
    }
  }

  &__close {
    background-color: transparent;
  }

  &__main {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
    margin-top: 77px;
    z-index: 10;
    padding: 0px 32px;
    flex: 1;
  }
}
