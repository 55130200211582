.landing-about-us {
  margin-top: 110px;
  margin-bottom: 110px;

  @media (max-width: 1150px) {
    margin-top: 80px;
  }
  @media (max-width: 800px) {
    margin-bottom: 64px;
  }

  &__main {
    margin-top: 48px;
    @media (max-width: 800px) {
      margin-top: 30px;
      padding: 0px 24px;
    }
  }

  &__blocks {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    max-width: 887px;
    margin: 0 auto;
    margin-bottom: 80px;
    @media (max-width: 800px) {
      margin-bottom: 45px;
    }
  }
  &__block {
    display: flex;
    background: #ffffff;
    border: 1px solid #e9ecf5;
    border-radius: 4px;
    padding: 24px 34px 24px 54px;
    margin-bottom: 16px;
    align-items: center;
    position: relative;
    @media (max-width: 800px) {
      padding: 17px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__block-text {
    & h2 {
      display: flex;
      font-family: "Oswald", sans-serif;
      font-weight: 400;
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 36px;
      text-transform: uppercase;
      align-items: center;
      color: #3b99cc;
      @media (max-width: 800px) {
        margin-right: 55px;
        font-size: 20px;
      }
      & span {
        background: #3b99cc;
        display: block;
        min-width: 10px;
        min-height: 10px;
        margin-right: 12px;
        border-radius: 50%;
      }
    }
  }

  &__block-paragraph {
    font-size: 15px;
    color: #727e8e;
    margin-bottom: 30px;

    @media (max-width: 800px) {
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
    & span {
      font-weight: 700;
    }
    & ul {
      margin-top: 7px;
      & li {
        list-style-type: disc;
        margin-left: 25px;
      }
    }
  }

  &__block-img {
    &--right {
      margin-left: 66px;
      margin-right: 25px;
    }
    &--left {
      margin-right: 56px;
    }
    @media (max-width: 800px) {
      margin: 0;
      width: 40px;
      right: 24px;
      position: absolute;
      top: 24px;
      & img {
        width: 100%;
      }
    }
  }

  &__video {
    max-width: 887px;
    margin: 0 auto;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    border: 10px solid #ffffff;
    border-radius: 8px;
    position: relative;
    z-index: 4;
    margin-top: 80px;

    & video {
      width: 100%;
      border-radius: inherit;
      height: 100%;
      display: block;
    }
    @media (max-width: 1150px) {
      max-width: 687px;
    }
    @media (max-width: 800px) {
      max-width: 100%;
    }
  }
  &__play {
    cursor: pointer;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
