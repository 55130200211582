.create-event {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__main {
    display: flex;
    padding-left: 64px;
    flex: 1 1;
    max-height: calc(100vh - 64px);
    @media (max-width: 900px) {
      padding-left: 0;
    }
  }

  &-content__form {
    width: 55%;
  }

  &__constructor-loader-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__constructor-loader {
    width: 75px;
    height: auto;
  }

  &__constructor {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    min-height: 470px;
  }

  &__constructor-buttons {
    flex-wrap: wrap;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }

  &__create-constructor {
    margin-bottom: 5px;
  }

  &__save-constructor {
    margin-bottom: 5px;
    margin-left: 10px;
  }

  &__questions {
    margin-top: 20px;

    &-title {
      font-weight: 400;
      font-size: 18px;
      color: #727e8e;
      line-height: 24px;

      margin-bottom: 20px;
    }

    &-main {
      margin-bottom: 25px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #727e8e;
      padding-bottom: 5px;

      input {
        padding: 3px 0 3px 5px;
        width: 100%;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #727e8e;
        border: 1px solid #3b99cc;
        border-radius: 4px;

        &:focus {
          border: 1px solid #3b99cc;
          border-radius: 4px;
        }
      }

      &-main {
        border-bottom: 1px solid #eceef5;
      }

      &-text--input {
        width: 80%;
        display: flex;
        justify-content: space-between;
      }

      &-icon {
        img {
          margin-right: 15px;
          cursor: pointer;

          &:last-child {
            margin-right: 5px;
          }
        }
      }

      &-save {
        width: 140px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #676c6f;
        padding-left: 5px;

        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
