.control-panel {
  display: inline-flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 12px 34px;
  border-radius: 30px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    max-width: 200px;

    & .panel-link {
      flex: 50%;
      margin-right: 15px;
      margin-bottom: 5px;
      font-size: 20px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media (max-width: 650px) {
      max-width: 250px;
    }
  }
}
