.created-web-info {
  margin-bottom: 0;
}

.promotion-create {
  position: relative;
  display: flex;
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  width: fit-content;
  margin-bottom: 15px;
  max-width: 431px;

  &__copy {
    cursor: pointer;
  }

  &__img {
    padding: 26px;
    background: #f7f8fc;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
      height: 70px;
    }
  }

  &__main {
    padding: 30px 25px;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3f4551;
  }

  &__text {
    font-size: 11px;
    line-height: 14px;
    color: #727e8e;
    margin-bottom: 7px;

    &.isCreated {
      border: 1px solid #e9ecf5;
      padding: 4px;
      width: 125px;
      margin-top: 10px;

      &.isCopied {
        img {
          width: 19px;
          height: 19px;
        }
      }
    }
  }

  &__settings {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    margin-right: 10px;
  }
}
