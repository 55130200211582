.program-popup {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  &__programs {
    list-style: none;
  }

  &__program {
    display: flex;
    justify-content: space-between;
    background: #f7f8fc;
    padding: 16px;
    border: 1px solid #e9ecf5;
    border-radius: 4px;
    margin-bottom: 8px;
    align-items: center;
  }

  &__time {
    margin-right: 16px;
    min-width: 87px;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 650px) {
      margin-right: 13px;
      min-width: 50px;
    }
  }

  &__text {
    font-size: 13px;
    color: #727e8e;
    line-height: 16px;
    flex: 1;
    overflow: hidden;
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    & button {
      font-size: 13px;
      text-decoration: underline;
      cursor: pointer;
      &:hover:not([disabled]) {
        text-decoration: none;
      }
    }
  }

  &__go-btn {
  }

  &__no-program {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;

    & img {
      width: 50px;
      margin-bottom: 24px;
    }

    & span {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #3f4551;
    }
  }

  &__loader {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
