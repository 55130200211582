.favorite {
  margin-left: 64px;

  padding-top: 28px;

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-left: 32px;
    margin-right: 32px;
    align-items: center;

    & h3 {
      color: #202531;
      font-size: 24px;
      font-weight: 500;
      margin-right: 10px;
    }
  }
  &__create-btn {
    font-weight: 500;
    font-size: 18px;
    padding: 8px 32px;
  }

  &__main {
    display: flex;
  }

  &__loader {
    margin: 0 auto;
    margin-top: 200px;
  }
}

@media (max-width: 900px) {
  .favorite {
    margin-left: 0px;
    padding-left: 15px;

    &-nav {
      padding-left: 0;
    }
    &__main {
      width: 100%;
      padding-bottom: 70px;
    }
    &__header {
      flex-wrap: wrap;
    }
    &__title {
      margin-bottom: 10px;
      padding: 0;
    }
    &__search {
      width: 80%;
    }
  }
}

@media (max-width: 800px) {
  .favorite {
    margin-left: 0;
    padding-left: 0;
    &__title {
      flex-wrap: wrap;
      text-align: center;
      & h3 {
        margin-bottom: 7px;
        flex: 100%;
      }
    }

    &__create-btn {
      margin: 0 auto;
      margin-bottom: 7px;
    }
    &__main {
      padding-bottom: 100px;
    }
  }
}

@media (max-width: 575px) {
  .favorite {
    margin-left: 0;
  }
}
