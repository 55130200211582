$avatar_margin: 8px;

.side-chat {
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(247, 248, 252, 1);
  display: flex;
  flex-direction: column;

  &--vr-event {
    .side-chat {
      &__main {
        padding-bottom: 70px;
        // padding-top: 160px;
      }
    }
  }

  &__header {
    padding: 32px 0 16px 0;
    z-index: 55;
    background-color: inherit;
    position: sticky;
    width: 100%;
  }

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
  }

  &__scroll {
    position: absolute;
    bottom: 0;
  }
  &__nav {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  &__nav-item {
    padding: 8px 24px;
    border: 1px solid #e9ecf5;
    border-radius: 30px;
    color: rgba(59, 153, 204, 1);
    font-size: 16px;
    font-weight: 500;
    margin-right: 12px;
  }
  &__nav-item--active {
    background: #ffff;
    color: rgba(63, 69, 81, 1);
  }

  &__close-btn {
    margin-right: 15px;
  }

  &__main {
    z-index: 5;
    flex: 1;
    overflow: hidden;
    height: auto;

    &--chat {
      z-index: 51;
    }
  }

  &__contacts {
    padding: 0px 30px;
    overflow: auto;
    height: 100%;
  }

  &__contact {
    display: flex;
    border-radius: 10px;
    border-bottom: 1px solid #e9ecf5;
    padding: 12px 16px;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #e9ecf5;
      transition: all 0.1s ease-out;
    }
  }
  &__contact-photo {
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    margin-right: 12px;
    width: 40px;
    height: 40px;
  }
  &__contact-info {
  }
  &__contact-fullname {
    color: rgba(45, 45, 45, 1);
    font-size: 13px;
    margin-bottom: 4px;
  }
  &__contact-position {
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
  }
  &__contact-count {
    background-color: #d63845;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
    display: inline-block;
    padding: 0px 5px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__contact-image {
    position: relative;
  }
  &__contact-online-status {
    color: rgba(79, 167, 215, 1);
    font-size: 13px;
  }

  &__messages {
    position: relative;
    height: 100%;
    list-style: none;
    overflow-y: auto;
    padding-left: 32px - ($avatar_margin / 2);
    padding-right: 32px - ($avatar_margin / 2);
    padding-bottom: 93px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    @media (max-width: 650px) {
      padding-bottom: 60px;
    }
  }

  &__message {
    display: flex;
    margin-bottom: 16px;
    @media (max-width: 650px) {
      flex: 100%;
    }
  }

  &__text {
    display: flex;
    max-width: 219px;
    flex-direction: column;
    @media (max-width: 650px) {
      max-width: 250px;
    }
  }

  &__message-text {
    padding: 12px;
    background: #e9ecf5;
    border-radius: 0px 10px 10px 10px;
    margin-bottom: 8px;
    color: rgba(114, 126, 142, 1);
    font-size: 13px;
    line-height: 16px;
  }

  &__userimg {
    width: 40px;
    height: 40px;
    margin-right: $avatar_margin;
    border-radius: 50%;
  }

  &__username {
    font-size: 11px;
    margin-bottom: 5px;
  }

  &__time {
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
  }

  &__message--self {
    align-self: flex-end;
    flex-direction: row-reverse;
    & .side-chat__userimg {
      display: block;
      margin-right: 0;
      margin-left: $avatar_margin;
    }

    & .side-chat__message-text {
      border-radius: 10px 0px 10px 10px;
    }

    & .side-chat__time {
      text-align: end;
    }

    & .side-chat__username {
      text-align: end;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    background: #f7f8fc;
    max-height: 100px;
    padding: 24px 32px;
    width: calc(100% - 20px);
    z-index: 66;
    @media (max-width: 650px) {
      width: 100vw;
      padding: 10px 23px;
    }
    &--events {
      width: auto;
    }
  }

  &__message-form {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    position: relative;
    border-radius: 30px;
  }

  &__add-file {
    background-color: transparent;
    margin-right: 12px;
    outline: none;
    cursor: pointer;
    position: absolute;
    left: 13px;
  }

  &__text-input {
    outline: none;
    flex: 1;
    font-size: 13px;
    color: rgba(63, 69, 81, 1);
    border: 1px solid rgba(233, 236, 245, 1);
    min-width: 75px;
    padding: 12px 54px 12px 16px;
    border-radius: inherit;
    &:focus {
      border: 1px solid rgba(59, 153, 204, 1);
    }
    &--error {
      border: 1px solid #c90113;
    }
  }

  &__send-btn {
    background: #3b99cc;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 12px;
  }

  &__form-error {
    font-size: 11px;
    margin-top: 2.5px;
    color: rgba(201, 1, 19, 1);
  }
}

.chat-current-user {
  background: #e9ecf5;
  padding: 10px 34px;
  display: flex;
  align-items: center;
  &__image-wrapper {
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 44px;

    margin-right: 12px;
    & img {
      width: 100%;
      border-radius: inherit;
    }
  }

  &__text {
  }

  &__name {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
}
