.header {
  background: #ffffff;
  position: relative;
  height: 64px;
  padding-left: 64px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(233, 236, 245, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 6;
  &__logo {
    margin-left: 33px;
    display: flex;
    align-items: center;
    & img {
      margin-right: 12px;
      height: 30px;
      width: 30px;
    }
    & span {
      font-size: 24px;
      text-transform: uppercase;
      font-family: "Oswald", sans-serif;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    padding: 7px 12px;
    background: #727e8e;
    border-radius: 4px;
    width: 370px;
    margin-right: 18px;
    font-size: 11px;
    & img {
      margin-right: 8px;
    }

    & span,
    a {
      font-size: 12px;
      color: #fff;
    }

    & a {
      text-decoration: underline !important;
      cursor: pointer;
      &:hover {
        text-decoration: none !important;
      }
    }

    @media (max-width: 700px) {
      flex: 1;
      max-width: none;
    }
  }

  &__create-event {
    border: 1px solid #3b99cc;
    border-radius: 50px;
    font-weight: 500;
    color: #3b99cc;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 4px 16px;
    margin-right: 10px;
    align-self: center;
    & img {
      margin-right: 8px;
      margin-bottom: 1px;
    }

    &:hover {
      color: #3b99cc;
      background-color: #d8f1ff;
    }

    @media (max-width: 1254px) {
      margin-right: 20px;
    }
  }

  &-nav {
    flex: 1;
    display: flex;
    margin-left: 53px;
    &__item {
      display: flex;
      align-items: center;
      padding: 9px 20px;
      opacity: 0.6;
      cursor: pointer;
      transition: all 1s ease-out;
      margin-right: 20px;

      &:hover {
        background: #e9ecf5;
        opacity: 1;
        transition: all 1s ease-out;
      }

      &.active {
        opacity: 1;
      }

      @media (max-width: 1254px) {
        padding: 0px;
      }
    }

    &__name {
      padding-left: 10px;

      color: #3f4551;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;

      @media (max-width: 900px) {
        display: none;
      }
    }

    &__icon {
      position: relative;
      top: -2px;
    }
  }

  &-profile {
    flex: 0 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 14px 32px 14px 0px;
    position: relative;
    margin-left: 15px;
    &__popup {
      position: absolute;
      right: 100%;
      top: 30px;
    }
    &__icon {
      padding-right: 32px;
      border-right: 1px solid #e9ecf5;
    }

    &__user {
      padding-right: 14px;
      cursor: pointer;
      margin-left: 20px;
      max-width: 180px;
      text-align: right;
    }

    &__user--popup {
      margin: 0 auto;
      text-align: center;
      padding-right: 0;
    }

    &__name {
      color: #2d2d2d;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }

    &__pos {
      color: #727e8e;
      font-size: 13px;
      line-height: 16px;
      text-align: right;
    }

    &__pos--popup {
      text-align: center;
    }

    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 1.5px solid #e9ecf5;
      margin-right: 7px;
      cursor: pointer;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
    }

    &__avatar--popup {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 60px;
        height: 60px;
      }
    }

    &__menu {
      cursor: pointer;
      position: relative;
    }
  }
}
.withoutShadow {
  box-shadow: none;
}
@media (max-width: 1300px) {
  .header {
    &-nav {
      margin-left: 15px;
    }
  }
}

@media (max-width: 1040px) {
  .header {
    &-profile {
      &__icon {
        padding-right: 20px;
      }
      &__user {
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 900px) {
  .header {
    padding-left: 0;

    &-nav__item {
      padding: 9px 10px;
    }

    &__logo {
      margin-left: 10px;
    }
  }
}

@media (max-width: 795px) {
  .header {
    height: 100%;
    width: 100%;
    padding: 15px;
    &__logo {
      justify-content: center;
      margin-left: 0;

      span {
        display: none;
      }
    }

    &-nav {
      flex: 1;
      margin-left: 0;
      &__item {
        margin-right: 10px;
      }
    }
    &-profile {
      &__popup {
        right: 120%;
      }
    }
  }
}

@media (max-width: 600px) {
  .header {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &__logo {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(0, -50%);
    }

    &-nav {
      width: 100%;
      justify-content: center;
    }
  }
}

@media (max-width: 400px) {
  .header__logo {
    top: 65%;
    left: 5px;
  }
}
