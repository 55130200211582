.promotion-item {
  border: 1px solid #e9ecf5;
  border-radius: 4px;

  margin-bottom: 15px;

  &__header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #202531;
  }

  &__settings {
    position: relative;
    display: flex;
  }

  &__settings-popup {
    width: 200px;
    position: absolute;
    z-index: 999;
    right: 0;
    top: 36px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &--stand {
      top: 40px;
    }
  }

  &__settings-popup-item {
    color: #202531;
    padding: 13px 20px;
    background: #ffffff;
    font-size: 13px;
    line-height: 16px;
    border-bottom: 1px solid #aeb0b4;
    font-weight: 400;
    text-align: start;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: #202531;
      text-decoration: underline;
    }
  }

  &__status {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #727e8e;
    border: 1px solid #e9ecf5;
    border-radius: 15px;
    padding: 3px 12px;

    margin-right: 16px;
    &--participants {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
    }
  }

  &__status--active {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #27ae60;
    border: 0.5px solid rgba(39, 174, 96, 0.7);
    border-radius: 15px;
    padding: 3px 12px;

    margin-right: 16px;
  }
  &__status--moderation {
    color: #fff;
    border: 1px solid #1000ff;
    background-color: #1000ff;
  }
  &__status--rejected {
    color: #fff;
    border: 1px solid #cc0000;
    background-color: #df5a5a;
  }
  &__status--payment {
    color: #fff;
    border: 1px solid #0094cc;
    background-color: #0094cc;
  }

  &__status--participants {
    margin-right: 0;
    width: 90px;
  }

  &__button {
    cursor: pointer;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 12px 16px;
    border-bottom: 1px solid #e9ecf5;
  }

  &__poster {
    width: 90px;
    height: 90px;
    z-index: 1;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
    }

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;

      background-image: url("../../../../assets/img/promotionLoupe.svg");
      background-repeat: no-repeat;
      background-position: right 7px bottom 7px;
    }
  }

  &__poster-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000000;
    opacity: 0.5;
    border-radius: inherit;
  }

  &__poster--notable {
    position: relative;
    background: transparent;
    border: 1px solid #e9ecf5;

    &::after {
      display: noone;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  &__info {
    width: 75%;
  }

  &__footer {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;

    &-item {
      width: 22%;
      color: #3f4551;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      padding-left: 25px;
    }
    &-item--views {
      background-image: url("../../../../assets/img/promotionViews.svg");
      background-repeat: no-repeat;
      background-position: left center;
    }
    &-item--clicks {
      background-image: url("../../../../assets/img/promotionClicks.svg");
      background-repeat: no-repeat;
    }
    &-item--date {
      background-image: url("../../../../assets/img/promotionDate.svg");
      background-repeat: no-repeat;
      background-position: left center;
      width: 26%;
    }
  }
}

@media (max-width: 1300px) {
  .promotion-item {
    &__info {
      width: 75%;
    }
  }
}

@media (max-width: 1100px) {
  .promotion-item {
    &__info {
      width: 70%;
    }

    &__footer {
      &-item {
        width: 22%;
      }

      &-item--date {
        width: 25%;
      }
    }
  }
}
