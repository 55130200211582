.upload-material-popup {
  overflow: auto;
  padding-top: 40px;
  .model-popup__file {
    height: 100%;
  }

  &__logo {
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  &__desc {
    & textarea {
      height: 93px;
      resize: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    & button {
      margin-left: 8px;
    }
  }

  &__content {
    height: 192px;
    width: 100%;
    position: relative;
    background: rgb(63, 69, 81);
    margin-bottom: 20px;
    border: 2px solid black;
  }

  &__img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
}
