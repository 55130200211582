.side-panel {
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 6vh;
  margin-left: 23px;
  margin-right: 40px;
  background-color: #fff;
  padding: 12px 34px;
  border-radius: 30px;
  align-self: flex-end;
}
