.event-hint {
  padding:10px 13px;
  background-color: #DDEFFA;
  font-size: 15px;
  line-height: 18px;
  color:#202531;
  display: inline-flex;
  align-items: center;
  margin:5px 0px;
  & img {
    margin-right: 9px;
    height:15px;
  }

  &--warning {
    background-color: #FFF1BD;
  }
}