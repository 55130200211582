.side-popup-chat {
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 383px;
  &--opened {
    height: 100vh;
  }
  &__button { 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    background: #202531;
    position: absolute;
    bottom: 23px;
    right: 23px;
    border-radius: 50%;
    z-index: 50;
  }
  &__unread {
    background-color: #d63845;
    color: #fff;
    font-size: 14px;
    border-radius: 50%;
    display: inline-block;
    padding: 0px 7px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__chat {
    height: 100%;
    width: 100%;
  }
}
