.confirmation-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  &__bg {
    height: 100%;
    width: 100%;
    background: #202531;
    opacity: 0.5;
    position: absolute;
  }
  &__wrapper {
    position: relative;
    background-color: #fff;
    width: auto;
    min-width: 500px;
    padding: 20px;
    border-radius: 4px;
  }
  &--view{
    &__wrapper {
      min-width: auto;
      padding: 5px;
    }
  }
  &__container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    color: rgba(63, 69, 81, 1);
  }
  &__close-btn {
    position: absolute;
    padding: 8px 10px;
    right: 5px;
    background: rgba(255,255,255,0.8);
    top: 5px;
    z-index: 1;
  }
  &__main {
    margin-top: 10px;
  }
  &__text {
  }
  &__buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  &__cancel-btn {
    margin-right: 7px;
  }
  & video, & object, & img {
    display: block;
    max-width: 800px;
    max-height: 500px;
  }
  & object{
    min-width: 700px;
    min-height: 400px;
  }
}
