.share-contacts {
  padding: 6px 24px;
  font-weight: 500;
  font-size: 16px;
  z-index: 49;
  color: #fff;
  background-color: rgba(201, 1, 19, 1);
  position: absolute;
  right: 32px;
  top: calc(64px + 26px);
  border-radius: 50px;
  text-align: center;
  &:hover,
  &:focus {
    background-color: rgba(142, 47, 57, 1);
    transition: 0.2s;
    color: #fff;
  }

  @media (max-width: 720px) {
    top: calc(130px + 46px);
    right: 36px;
  }
  @media (max-width: 650px) {
    left: 50%;
    transform: translate(-50%);
  }
}
