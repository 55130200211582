.registration-final {
  position: relative;
  background: #f7f8fc;
  padding-top: 90px;
  min-height: 100vh;
  height: 100%;
  &__error {
    color: red;
    font-weight: 500;
    font-size: 14px;
  }

  &__main {
    width: 40%;
    margin: 0 auto;
  }

  &__title {
    color: #202531;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 25px;
    text-align: center;
  }

  &__subtitle {
    color: #727e8e;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    margin-bottom: 15px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
  &__back {
    float: left;
    display: flex;

    color: #727e8e;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    span {
      position: relative;
      top: 7px;
    }

    &:hover {
      text-decoration: underline;
      color: #727e8e;
    }
  }
  &__submit {
    float: right;
  }

  &__radio {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      width: 100%;
      display: block;
      color: #3d4453;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;

      margin-bottom: 5px;
    }
    input {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
    label {
      font-weight: 400;
      color: #202531;
      font-size: 13px;
      line-height: 16px;

      margin-right: 20px;
    }
  }
}

@media (max-width: 1300px) {
  .registration-final {
    padding-top: 15px;
  }
}

@media (max-width: 1100px) {
  .registration-final {
    &__main {
      width: 60%;
    }
  }
}

@media (max-width: 800px) {
  .registration-final {
    padding-top: 30px;
    height: 100%;

    &__main {
      margin: 0 0;
      padding: 15px;
      width: 100%;
      padding-top: 100px;
    }
  }
}

@media (max-height: 900px) {
  .registration-final {
    padding-top: 20px;

    &__title {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@media (max-height: 500px) {
  .registration-final {
    height: 100%;
    &__main {
      width: 60%;
    }
  }
}
