.organizer-panel {
  display: flex;
  &__main {
    display: flex;
    flex: 1 1;
    max-height: calc(100vh - 64px);
    overflow: auto;
    @media (max-width: 900px) {
      padding-left: 0;
    }
  }
}
