.nav-profile {
  min-height: calc(100vh - 64px);
  padding: 15px 25px 0 25px;
  width: 27%;
  background: #f7f8fc;
  flex: 0 0 298px;
  display: flex;
  flex-direction: column;

  @media (max-width: 795px) {
    min-height: calc(100vh - 100px);
  }

  &__send-btn {
    margin-top: auto;
    margin-bottom: 15px;
    align-self: center;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding: 8px 10px;

    transition: all 1s ease-out;

    cursor: pointer;

    &:hover {
      background: #ebecf0;
      transition: all 1s ease-out;
    }
    &.active {
      background: #e9ecf5;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__name {
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  & .active &__name {
    color: #202531;
  }

  & .active &__item {
    background: #e9ecf5;
  }
}

@media (max-width: 1000px) {
  .nav-profile {
    padding-right: 15px;
  }
}

@media (max-width: 900px) {
  .nav-profile {
    width: 45px;
    padding: 15px;

    & .active &__item {
      background: transparent;
    }

    &__item {
      padding: 0;

      &.active {
        background: transparent;
      }
    }

    &__name {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  .nav-profile {
    & .active &__item {
      background: transparent;
    }

    &__item {
      padding: 0;

      &.active {
        background: transparent;
      }
    }
    &__name {
      display: none;
    }
  }
}
