.members-popup {
  &__list {
  }

  &__item {
  }
}

.member-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 9px;
  & a {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid #3b99cc;
  }

  &__header {
    padding: 13px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f8fc;
    border-radius: 5px 5px 0px 0px;
    @media (max-width: 650px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__image {
    max-height: 40px;
    width: auto;
    max-width: 180px;
    flex: 0 0 130px;
    padding-right: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      width: 100%;
    }
    @media (max-width: 650px) {
      margin-bottom: 7px;
      padding-right: 0;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    @media (max-width: 650px) {
      margin-bottom: 7px;
      font-size: 20px;
    }
  }

  &__stend {
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
    padding: 3px 12px;
    border: 1px solid #e9ecf5;
    border-radius: 15px;
    @media (max-width: 650px) {
      font-size: 15px;
    }
  }

  &__main {
    padding: 16px;
    border-radius: 0px 0px 5px 5px;
  }

  &__sector {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    word-break: break-all;
    line-height: 20px;
    & h3 {
      color: rgba(114, 126, 142, 1);
      font-size: 13px;
      font-weight: 400;
      flex: 0 0 130px;
      @media (max-width: 650px) {
        font-size: 15px;
        flex: 0 0 70px;
        margin-right: 10px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    font-size: 13px;
    flex: 1;
    @media (max-width: 650px) {
      font-size: 15px;
    }
  }
}
