.landing {
  background-color: #f7f8fc;
  overflow: hidden;

  &__block-title {
    font-size: 42px;
    color: #3f4551;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    text-align: center;
    @media (max-width: 800px) {
      font-size: 24px;
    }
  }
  &__block-post-title {
    color: #727e8e;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-top: 5px;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__registration {
    display: flex;
    align-items: center;
    justify-content: center;
    & a {
      padding: 8px 32px;
    }
  }

  &__up-btn {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px;
    height: 115px;
    cursor: pointer;
    background-color: #3b99cc;
    z-index: 10;
    border-radius: 4px;
    @media (max-width: 800px) {
      top: auto;
      height: 92px;
      width: 46px;
    }
  }
}
