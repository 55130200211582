.upload-popup {
  position: fixed;
  top: 0;
  z-index: 666;
  &__bg {
    z-index: 777;
    height: 100vh;
    width: 100vw;
    background-color: rgba(32, 37, 49, 1);
    opacity: 0.5;
    top: 0;
    left: 0;
  }
  &__main {
    z-index: 888;
    background: #fff;
    width: 660px;
    padding: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
    & h3 {
      color: #3f4551;
      font-size: 24px;
    }
    & button {
      & img {
        height: 12px;
        width: 12px;
      }
    }
  }
  &__posttitle {
    font-size: 13px;
    color: #727e8e;
    margin-bottom: 27px;
    & h3 {
      font-size: 13px;
    }
  }
  &__buttons {
    display: flex;
    align-self: flex-end;
    & button {
      margin-left: 24px;
    }
  }
}
