.registration__main {
  padding-top: 100px;
  position: relative;
  width: 50%;
  padding-left: 65px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 70px;
    left: 0;
    background-image: url("../../../assets/img/bgRegistration.png");
    background-position: center;
    background-repeat: no-repeat;
    left: 50%;
    transform: translate(-50%);
  }
}

.registration__logo {
  position: absolute;
  width: 175px;
  height: 36px;
  top: 24px;
  left: 24px;

  z-index: 999;
}

.badge{
  font-weight: normal;
  &-danger{
    background: #e24343;
    color: #fff;
  }
  &-success{
    background: #43e255;
    color: #fff;
  }
  &-primary{
    background: #435be2;
    color: #fff;
  }
  &-warning{
    background: #e2bd43;
    color: rgb(36, 36, 36);
  }
  &-info{
    background: #43b0e2;
    color: rgb(255, 255, 255);
  }
  &-light{
    background: #c7c7c7;
    color: rgb(36, 36, 36);
  }
  &-dark{
    color: #c7c7c7;
    background: rgb(36, 36, 36);
  }
  
  &-sm {
    padding: .2rem .6rem;
  }

  &-md {
    font-size: 0.85rem;
    padding: .45rem .65rem;
  }

  &-lg {
    font-size: 1.25rem;
    padding: .55rem .85rem;
  }
}

.registration__title {
  padding-left: 25px;
  width: 55%;
}

@media (max-width: 1400px) {
  .registration__main {
    padding-top: 75px;
    padding-left: 25px;

    &::after {
      background-size: contain;
      background-position: center top 100px;
    }
  }
  .registration__title {
    padding-left: 0;
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .registration__main {
    display: none;
  }
  .registration__logo {
    z-index: 9999;
    position: absolute;
    top: 15px;
    right: 51px;
  }
}

@media (max-height: 900px) {
  .registration__logo {
    top: 5px;
  }
  .registration__main::after {
    background-position: center;
  }
}

@media (max-height: 700px) {
  .registration__main {
    padding-top: 60px;
  }
  .registration__main::after {
    background-position: center;
  }
  .registration__title {
    width: 80%;
  }
}

@media (max-height: 400px) {
  .registration__main {
    display: none;
  }
  .registration__logo {
    z-index: 9999;
    position: absolute;
    right: 51px;
  }
  .registration__title {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .registration__logo {
    width: 88%;
    position: absolute;
    text-align: center;
    margin-top: 30px;
  }
}
