.events-header {
  display: flex;
  color: #fff;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  height: 64px;
  @media (max-width: 720px) {
    flex-wrap: wrap;
    height: 130px;
    padding: 10px 0;
  }

  &__bg {
    background-color: #202531;
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    z-index: -1;
    opacity: 0.45;
  }

  &__info {
    margin-left: auto;
    margin-right: 30px;
    @media (max-width: 720px) {
      margin-left: 20px;
      width: 100%;

      .header__info {
        width: 100%;
        max-width: max-content;
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
    @media (max-width: 720px) {
      flex: 100%;
      padding: 5px 20px;
    }
  }

  &__exit {
    font-size: 11px;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 56px;
    background: inherit;
    &:hover,
    &:focus {
      text-decoration: underline;
      color: #fff;
    }
    & img {
      margin-right: 11px;
    }
    @media (max-width: 720px) {
      margin-left: 0;
      margin-right: 15px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    margin-right: 40px;
    @media (max-width: 720px) {
      margin-right: 0;
      font-size: 20px;
    }
  }

  &__user-panel {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-right: 14px;
  }

  &__username {
    margin-bottom: 4px;
  }

  &__user-role {
    text-align: end;
  }

  &__user-photo {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    cursor: pointer;
  }

  &__user-photo span {
    margin-top: 5px;
    margin-right: 5px;
    font-size: 12px;
  }

  &__dropdown-btn {
    background-color: transparent;
    margin-right: 35px;
    margin-left: 10px;
    cursor: pointer;
    outline: none;
    position: relative;
  }

  &__popup {
    position: absolute;
    right: 100%;
    top: 30px;
    @media (max-width: 720px) {
      right: -100px;
    }

    @media (max-width: 575px) {
    }
  }
}
