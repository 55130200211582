.event-calendar {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    padding: 15px;
    padding-bottom: 75px;
  }

  &__form {
    position: relative;
  }

  &__loading {
    color: #202531;
    font-size: 24px;
    font-weight: 500;
  }

  &__loader {
    min-height: 400px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__icon {
    width: 20px;
    height: 20px;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z' stroke='%23727E8E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.75 15.75L12.4875 12.4875' stroke='%23727E8E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.4s;
    &:hover {
      opacity: 1;
    }
  }

  &__main {
    width: calc(100% - 350px);
    flex: 1;

    @media (max-width: 900px) {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 30px;
    margin-bottom: 15px;
    margin-top: 23px;

    @media (max-width: 900px) {
      flex-wrap: wrap;
      margin: 0px 15px;
    }

    @media (max-width: 700px) {
      flex-wrap: wrap;
    }
  }

  &__title {
    color: #202531;
    font-size: 24px;
    font-weight: 500;
    margin-right: 24px;
    @media (max-width: 900px) {
      width: 100%;
      margin-bottom: 10px;
      padding-left: 0;
    }
  }

  &__search {
    flex: 1;
    margin-right: 30px;
    border-radius: 4px;
    @media (max-width: 700px) {
      flex: 100%;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-end;

    &-item {
      width: 50%;
      cursor: pointer;
      margin-right: 15px;
      opacity: 0.5;
    }

    & .active &-item {
      opacity: 1;
    }

    &--favorite {
      justify-content: center;
    }
  }

  &__input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;

    border: 1px solid #e9ecf5;
    padding: 12px 0 12px 14px;

    &:focus {
      outline: none;
      border: 1px solid #3b99cc;
    }

    &.filled {
      outline: none;
      border: 1px solid #727e8e;
    }
  }

  &__content {
    padding: 0px 18px;
    margin-bottom: 20px;
    flex: 1;
  }
}

.event-calendar--actual {
  padding-left: 0;
}

@media (max-width: 1000px) {
  .event-calendar__content {
    margin-top: 5px;
    padding: 0 10px;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .event-calendar {
    flex-wrap: wrap;
    &__main {
      width: 100%;
    }
    &__content {
      margin-top: 5px;
      padding: 0px 10px;
      .calendar__title {
        margin-left: 20px;
      }
    }
    &__header {
      flex-wrap: wrap;
      padding-left: 0;
    }
    &__title {
      width: 100%;
      margin: 15px 0;
    }
  }
}

@media (max-width: 655px) {
  .page-wrapper {
    height: 100%;
  }
}
