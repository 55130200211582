.input {
  position: relative;
  display: flex;
  flex-direction: column;

  padding-bottom: 20px;

  &--create-ad {
    margin-top: 20px;
  }

  &--profile {
    & label {
      margin-bottom: 5px;
    }
    & .react-datepicker__triangle {
      left: -10px !important;
    }
  }

  &--short-description {
    max-width: 650px;
  }

  &__alert {
    display: flex;
    align-items: baseline;

    & label {
      margin-right: 5px;
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #3d4453;
    }
  }

  &__label {
    color: #3d4453;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  &__label--advantages {
    margin-bottom: 5px;
  }

  &__wrapper {
    position: relative;
    margin-top: 5px;
    & .input {
      &__main {
        margin-top: 0;
        width: 100%;
        padding-right: 50px;
      }
    }
  }

  &__reset-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    &--on-top {
      top: 16px;
    }
    &--on-bottom {
      top: auto;
      transform: none;
      bottom: 16px;
    }
  }

  &__main {
    margin-top: 5px;
    padding: 12px 14px;

    color: #3f4551;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    border: 1px solid #e9ecf5;
    border-radius: 4px;

    background-color: #fff;

    &:disabled {
      background-color: #fff;
    }

    &--participants {
      margin-bottom: 10px;
    }

    &::placeholder {
      color: #727e8e;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      white-space: pre-line;
    }

    &.filled {
      outline: none;
      border: 1px solid #727e8e;
    }

    &:focus {
      outline: none;
      border: 1px solid #3b99cc;
    }

    &.error {
      outline: none;
      border: 1px solid #c90113;
    }
    &.success {
      outline: none;
      border: 1px solid #008a57;
    }

    &__main--advantages {
      margin-top: 10px;
    }
  }

  &__text {
    color: #c90113;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    padding-top: 5px;
  }
  &__select {
    background-color: #fff;
    background-image: url("./../../../assets/img/profileDropdown.svg");
    background-repeat: no-repeat;
    background-position: right 12px top 50%, 0 0;

    cursor: pointer;
  }

  &__date {
    width: 100%;
    position: relative;
  }

  &__date::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    top: 0;
    left: -50px;
    padding: 15px 35px 15px 35px;
    opacity: 0;

    cursor: pointer;
  }

  &__date--promotion {
    width: 50%;
  }
  &__info {
    margin-left: 10px;

    cursor: pointer;
  }
  &__password {
    margin-top: 5px;
    color: #4fa7d7;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
  }

  &__program--time {
    width: 20%;
  }

  &__program--event {
    width: 55%;
    margin-left: 25px;
  }

  textarea {
    height: 130px;
    resize: none;
  }
}

.input--create-stand {
  label {
    margin-right: 5px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3d4453;
  }
}

.input--promotion {
  width: 45%;
  & input {
    z-index: 2;
  }
}

.create-promotion__date-icon {
  position: absolute;
  top: 45%;
  right: 4%;
  width: 16px;
  height: 16px;
  z-index: 1;
}

.input--webinar {
  label {
    color: #202531;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &::placeholder {
    color: #727e8e;
  }

  &__day {
    width: 35%;
  }

  &__time {
    width: 25%;
  }
}
