.entry {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: #f7f8fc;
  padding: 70px 65px 40px 120px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    width: 70%;
    height: calc(100% - 40px);
    bottom: 40px;
    right: 0;
    background-image: url("../../assets/img/bgEntry.png");
    background-position: right;
    background-repeat: no-repeat;
  }

  &__main {
    position: relative;
    top: 0;
    left: 0;
    z-index: 999;

    width: 33%;
  }

  &__title {
    font-family: "Oswald";
    color: #3f4551;
    font-weight: 400;
    font-size: 42px;
    line-height: 62px;
    text-transform: uppercase;

    margin-bottom: 8px;
  }

  &__title--mod {
    font-family: "Oswald";
    color: #3f4551;
    font-weight: 400;
    font-size: 36px;
    line-height: 53px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    & span {
      width: 100%;
    }
  }

  &__subtitle {
    color: #727e8e;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    padding-bottom: 30px;
  }

  &__footer {
    width: 85%;
    display: flex;
    position: fixed;
    left: 120px;
    bottom: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 999;
    margin-top: 230px;
  }

  &__login {
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    transition: all 1s ease-out;

    cursor: pointer;

    &:hover {
      text-decoration: underline;

      transition: all 1s ease-out;
    }

    span {
      color: #3b99cc;
    }
  }

  &__logo {
    margin-bottom: 100px;
  }
}

@media (max-width: 1400px) {
  .entry {
    min-height: 100vh;
    padding-left: 60px;

    &__logo {
      margin-bottom: 50px;
    }

    &__main {
      padding-right: 50px;
    }

    &__footer {
      left: 60px;
    }
  }
}

@media (max-width: 1300px) {
  .entry {
    padding-top: 30px;
  }
}

@media (max-width: 1000px) {
  .entry {
    &::after {
      display: none;
    }
    &__main {
      width: 100%;
    }
  }
}

@media (max-width: 850px) {
  .entry {
    padding: 30px 15px;

    &__logo {
      margin-bottom: 50px;
    }
    &__footer {
      left: 15px;
    }
  }
}

@media (max-width: 575px) {
  .entry {
    padding: 15px;
    padding-top: 70px;

    &__main {
      text-align: center;
      padding-right: 0px;
    }
  }
}

@media (max-width: 350px) {
  .entry {
    padding-top: 5px;

    &__logo {
      margin-bottom: 10px;
    }
  }
}

@media (max-height: 900px) {
  .entry::after {
    background-size: contain;
    background-position: right top -30px;
  }
}

@media (max-height: 500px) {
  .entry {
    height: 100%;
    padding-left: 20px;

    &__logo {
      margin-bottom: 20px;
    }

    &__main {
      padding-right: 10px;
    }

    &__title {
      width: 100%;
    }
    &__subtitle {
      padding-bottom: 15px;
    }
    &__footer {
      left: 20px;
    }
    &__title--mod {
      font-size: 24px;
    }
  }
}

@media (max-width: 1200px) {
  .entry::after {
    background-size: contain;
    background-position: right -150px top -30px;
  }
}


@media (min-width: 1600px) and (max-width: 2050px) {
  .entry {
    &__title {
      width: 460px;
    }
  }
}

