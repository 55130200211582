.promotion {
  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #727e8e;
  }
  &__main {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
  }
  &__banners {
    width: 48%;
    margin-right: 2%;
  }
  &__buttons {
  }
}

.create-promotion {
  width: 70%;
  flex: 1;
  padding-right: 32px;
  padding-top: 25px;
  padding-left: 32px;
  @media (max-width: 900px) {
    padding-bottom: 70px;
  }

  &__site-wrapper {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999;
    min-height: 100vh;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__header-btns {
    display: flex;
    & button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__widget-wrapper {
    flex: 0 49%;
  }

  &__back {
    display: flex;
    align-items: center;

    img {
      padding-right: 10px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #2d2d2d;
  }

  &__posttitle {
    margin-bottom: 7px;
  }

  &__banner-radios {
    margin-bottom: 15px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #727e8e;
  }

  &__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 24px;
    border: 0.5px solid #3b99cc;
    background: transparent;
    color: #3b99cc;

    &:hover {
      color: darken(#3b99cc, 20%);
      border-color: darken(#3b99cc, 20%);
      background: transparent;
    }
  }
  &__main {
    display: flex;
    justify-content: space-between;
    &--web {
      flex-direction: column;
      width: 100%;
    }
  }

  &__form {
    width: 48%;

    padding-bottom: 20px;
  }

  &__radio {
    margin-bottom: 15px;

    &-item {
      & label {
        display: flex;
        align-items: center;
      }
      margin-bottom: 10px;
    }

    span {
      width: 100%;
      display: block;
      color: #3d4453;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 5px;
    }

    input {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }

    label {
      font-weight: 400;
      color: #202531;
      font-size: 13px;
      line-height: 16px;
      margin-right: 20px;
    }
  }

  &__grid-radio {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    row-gap: 10px;
    @media (max-width: 1542px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__grid-radio-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #202531;
    font-size: 13px;
    line-height: 16px;

    input {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  }

  &__date {
    display: flex;
    justify-content: space-between;
    z-index: 99;
    position: relative;

    & input:not([disabled]) {
      background: transparent;
    }
  }

  &__date-icon {
    z-index: 1;
  }

  &__date--create-event {
    width: 80%;
  }

  &__footer {
    padding: 16px;
    background: #f7f8fc;
    border-top: 1px solid #e9ecf5;
    margin-bottom: 40px;
  }

  &__footer-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__total {
    color: #3f4551;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }

  &__price {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #3f4551;
  }

  &__item {
    border: 1px solid #e9ecf5;
    border-radius: 4px;

    margin-bottom: 15px;
  }

  &__buttons {
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    & button {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.web-item {
  &__input-wrapper {
    width: 100%;
    position: relative;
    & input {
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }

  &__date-icon {
    position: absolute;
    z-index: 2;
    right: 13px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
  }

  &__program-description {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__program-description-wrapper {
    display: flex;
    align-items: center;

    & input {
      max-width: 415px;
      width: 100%;
      margin-right: 28px;
    }
  }

  &__program-description-delete {
    cursor: pointer;
  }
}

.web {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    background: #f7f8fc;
    cursor: pointer;

    &__title {
      font-weight: 400;
      color: #3f4551;
      font-size: 18px;
      line-height: 24px;
    }

    &__button {
      cursor: pointer;
    }
  }

  &-item {
    padding: 25px;

    &__time-wrapper {
      flex: 0 160px;
      min-width: 20%;
    }

    &__program-times {
      display: flex;
    }

    &__time-input-wrapper {
      margin-right: 10px;
      flex: 50%;
      &:last-child {
        margin-right: 0;
      }
    }

    &__program-time {
      display: flex;
    }

    &__form {
      width: 57%;
    }
    &__dropfield {
      width: 40%;
    }
    &__dropfield-mini {
      width: 20%;
    }
    &__about {
      border-bottom: 1px solid #e9ecf5;

      padding-bottom: 15px;

      &-form {
        display: flex;
        justify-content: space-between;
      }

      &-input {
        width: 58%;

        textarea {
          height: 180px;
        }
      }
    }
    &__product {
      border-bottom: 1px solid #e9ecf5;
      margin-bottom: 15px;
      padding-bottom: 25px;
      margin-top: 10px;

      &.clear {
        margin-bottom: 0px;
        padding-bottom: 0px;
      }

      &-item {
        width: 100%;
        display: flex;

        margin-bottom: 40px;
      }

      &-inputs {
        width: 55%;
        margin-left: 25px;
      }

      &-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3f4551;

        margin-bottom: 10px;
      }

      &-delete {
        width: 12px;
        height: 12px;
        cursor: pointer;
        margin-left: 10px;

        &.program {
          position: absolute;
          position: absolute;
          top: 50%;
          right: -15%;
          transform: translate(0, -50%);
          z-index: 1;
        }
      }

      &-input {
        input {
          width: 92%;
        }
      }
    }

    &__img-delete {
      position: absolute;
      right: 20px;
      top: 15px;
      z-index: 100;

      img {
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      &.pdf {
        margin-left: 20px;

        img {
          top: 45%;
        }
      }

      &.product {
        margin-left: 15px;
      }

      &.background-organizer {
        img {
          top: 40%;
        }
      }
    }

    &__img-title {
      text-align: center;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #202531;

      &.mini {
        margin-bottom: 5px;
      }
    }

    &__video-main {
      display: flex;
    }

    &__video-dropfield {
      width: 45%;
      margin-bottom: 15px;
    }

    &__video-inputs {
      width: 56%;
    }

    &__program {
      margin-bottom: 20px;
      padding: 16px 27px;
      background: #f7f8fc;
      border-radius: 4px;
      &-input {
        .react-datepicker-wrapper {
          display: block;
        }
      }

      &-main {
      }
    }

    &__program-inputs {
      display: flex;
    }
  }
  &-item--top {
    display: flex;
    justify-content: space-between;
  }

  &-item--main {
    padding-bottom: 0px;
  }

  &-colors__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    &-text {
      width: 25%;
      font-weight: 400;
      color: #3f4551;
      font-size: 15px;
      line-height: 20px;
    }

    &-colors {
      display: flex;
      width: 73%;
    }
  }
}

.promotion-popup {
  position: absolute;
  width: 100%;
  height: 100%;

  &__main {
    z-index: 1000;
    position: fixed;
    background: #fff;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 25px;
  }

  &__banner {
    img {
      max-width: 100%;
      max-height: 540px;
    }
  }

  &__close {
    position: absolute;
    top: 3px;
    right: 5px;
    width: 25px;
    height: 25px;

    cursor: pointer;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: black;
    opacity: 0.1;
  }
}

.popup-user__add--date {
  margin-top: 10px;
}

@media (max-width: 900px) {
  .create-promotion {
    width: 90%;
  }
}
