.events-registration {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__title {
    padding-top: 30px;
    color: #202531;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    text-align: center;
    margin-bottom: 25px;
  }

  &__title-preview {
    width: 100%;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    color: #3f4551;

    margin-bottom: 15px;
  }

  &__subtitle-preview {
    font-weight: 400;
    font-size: 18px;
    line-height: 48px;
    color: #727e8e;
    opacity: 0.9;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &__preview {
    padding: 60px 0 0 70px;
    width: 55%;

    @media (max-width: 900px) {
      padding: 25px 0 0 25px;
    }

    &-main {
      width: 63%;
    }
  }

  &__logo {
    margin-bottom: 35px;
    width: 225px;
    height: 225px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    & img {
      width: 100%;
      height: auto;
    }
  }

  &__date {
    width: 60%;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
    color: #3f4551;
    min-width: max-content;

    border: 1px solid rgba(29, 104, 123, 0.4);
    border-radius: 40px;
    padding: 0px 9px;

    margin-bottom: 30px;
    text-align: center;
  }

  &__main {
    background: #f7f8fc;
    position: relative;
    width: 45%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__link {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
  }
}

@media (max-width: 1400px) {
  .events-registration {
    &__preview-main {
      width: 80%;
    }

    &__link {
      margin-top: 30px;
    }
  }
}

@media (max-width: 800px) {
  .events-registration {
    height: 100%;

    &__main {
      width: 90%;
    }

    &__link {
      margin-top: 20px;
    }
  }
}
