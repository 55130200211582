.back-to-lobby {
  position: absolute;
  z-index: 666;
  left: 38px;
  top: 90px;
  text-align: center;
  @media (max-width: 720px) {
    top: calc(130px + 46px);
  }
  @media (max-width: 650px) {
    top: calc(130px + 5px);
    left: 50%;
    transform: translate(-50%);
  }
  &__button {
    padding: 6px 24px;
    border-radius: 50px;
    background-color: rgba(201, 1, 19, 1);
    color: rgba(242, 242, 242, 1);
    font-weight: 500;
    font-size: 16px;
    &:hover,
    &:focus {
      background-color: rgba(142, 47, 57, 1);
      transition: 0.2s;
      color: #fff;
    }
  }
}
