* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:focus,
  &:active {
    outline: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

button {
  cursor: pointer;
  background-color: transparent;
  &:hover,
  &:focus {
    outline: none;
  }
}

ul {
  padding-left: 0;
  & li {
    list-style: none;
  }
}

input {
  &:focus {
    outline: none;
  }
}

select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea {
  outline: none;
}

.table {
  > :not(:first-child) {
    border-top: 1px transparent !important;
  }
}
