.color-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 500;

  & .chrome-picker {
    width: 100% !important;
    margin-bottom: 15px;

    & div {
      &:nth-child(2) {
        & .flexbox-fix {
          &:nth-child(2) {
            display: none !important;
          }
        }
      }
    }
  }

  &__bg {
    height: 100%;
    width: 100%;
    background: #202531;
    opacity: 0.5;
    position: absolute;
  }
  &__wrapper {
    position: relative;
    background-color: #fff;
    width: 452px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 33px;
    border-radius: 4px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    & h2 {
      font-weight: 500;
      color: rgba(63, 69, 81, 1);
      font-size: 24px;
      font-weight: 500;
    }
  }

  &__paint-types {
    border-bottom: 1px solid #e9ecf5;
    padding-bottom: 21px;
  }

  &__paint-type {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    & input {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    & span {
      font-size: 13px;
      line-height: 16px;
      color: #202531;
    }
  }

  &__main {
    margin-top: 24px;
  }

  &__cmyk-inputs {
    display: flex;
  }

  &__cmyk-item {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    & span {
    }

    & input {
      width: 100%;
      min-width: 50px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__color-item {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    margin-right: 13px;
    margin-bottom: 24px;
    &:nth-child(9n) {
      margin-right: 0;
    }
    &--active {
      border: 6px solid #c3c3c3;
    }
  }

  &__textures {
    display: flex;
    flex-wrap: wrap;
  }

  &__texture {
    padding: 8px;
    width: 104px;
    height: 104px;
    margin-right: 3%;
    margin-bottom: 24px;
    border: 1px solid #e9ecf5;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &--selected {
      border: 2px solid #3b99cc;
      position: relative;
      &::before {
        content: url("../../../../assets/img/selected-item-mark.svg");
        position: absolute;
        bottom: 8px;
        left: 8px;
        background: #fff;
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &__cancel-btn {
    margin-right: 24px;
  }

  &__confirm-btn {
  }
}
