.analytics-traffic {
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f7f8fc;
    padding: 11px 15px;
  }
  &__title {
    margin-right: 15px;
    font-weight: 500;
    font-size: 24px;
    color: rgba(45, 45, 45, 1);
  }
  &__more-btn {
    background: #ffffff;
    border: 1px solid #e9ecf5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }
  &__main {
    padding: 23px 16px;
    display: flex;
    @media (max-width: 1480px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__circle {
    width: 169px;
    height: 169px;
    border-radius: 50%;
    margin-right: 53px;
    @media (max-width: 1480px) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    @media (max-width: 1480px) {
      flex: 100%;
      margin-left: 30px;
      margin-top: 10px;
    }
  }
  &__source {
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
    position: relative;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    & span {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: -15px;
      bottom: 4px;
      display: block;
    }
  }

  &__tooltip {
    background-color: #fff;
    color: #727e8e;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    padding: 4px 6px;
  }
}
