.error-page {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fc;

  padding-top: 100px;

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/404Bg.png");
    background-repeat: no-repeat;
    background-position: center;
  }

  &-bg--extra {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/extraBg.png");
    background-repeat: no-repeat;
    background-position: center left 57%;
  }

  &__title {
    font-family: "Oswald";
    font-size: 36px;
    line-height: 53px;
    text-align: center;
    text-transform: uppercase;
    color: #3f4551;

    margin-bottom: 15px;
  }

  &__content {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
  }

  &__main {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  &__main--extra {
    padding: 0;
  }

  &__text {
    width: 35%;
    color: #727e8e;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    margin: 0 auto;
    margin-bottom: 40px;
  }
}

@media (max-width: 1400px) {
  .error-page {
    padding-top: 50px;

    &__title {
      margin-bottom: 20px;
    }
  }
  .error-page__main {
    padding-right: 50px;
    padding-left: 30px;
  }
  .error-page__bg {
    background-size: contain;
  }
}

@media (max-width: 800px) {
  .error-page__bg {
    display: none;
  }
  .error-page__main {
    width: 100%;
    padding: 15px;
    padding-top: 50px;
  }
}

@media (max-height: 700px) {
  .error-page {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

@media (max-height: 400px) {
  .error-page {
    padding-top: 50px;
    position: relative;

    &__title {
      width: 90%;
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
    }
  }
  .error-page__bg {
    display: none;
  }
  .error-page__main {
    width: 100%;
    padding: 15px;
    padding-top: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 575px) {
  .error-page {
    padding-top: 80px;
  }
}
