.panel-link {
  margin-right: 32px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #727e8e;
  text-decoration: none;

  &--webinar-started {
    & .panel-link__img-wrapper {
      position: relative;
      display: block;
      &::after {
        position: absolute;
        right: 15px;
        top: 4px;
        background: red;
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }
  & img {
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &--active {
    color: rgba(59, 153, 204, 1);
  }

  &--disabled {
    opacity: 0.5;
    &:hover {
      color: #727e8e;
      text-decoration: none;
    }
  }
}
