.model-wrapper {
  ::-webkit-scrollbar {
    display: none;
  }
}

.model {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  background-size: cover;

  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  flex: 1;
  z-index: 1;

  &--event {
    background-image: url("../../assets/img/reception-bg.png");

    & .model {
      &__plan-wrapper {
        display: flex;
        align-items: center;
        top: 10vh;
        right: 5vw;
        overflow: hidden;
      }

      &__plan {
      }
    }
  }

  &--stand {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    & .model {
      &__plan-wrapper {
        overflow: hidden;
        display: flex;
        align-items: center;
        ::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background: transparent;
        }
      }

      &__wrap-image {
      }
    }
  }

  @media (max-width: 1250px) {
    min-width: 920px;
    background-attachment: initial;
  }

  @media (max-width: 900px) {
    margin-bottom: 20px;
  }

  display: flex;

  &__mask {
    width: 100%;
    position: absolute;
    z-index: 50;
    & canvas {
      width: 100%;
    }
  }

  &__audio-notice {
    font-size: 13px;
    color: #000;
    margin: 10px 0px;
    & div {
      margin-bottom: 4px;
    }
  }

  &__change-color-btn {
    position: absolute;
    cursor: pointer;
    z-index: 99;
  }

  &__mask-canvas {
  }

  &__create-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 99;
  }

  &__banner-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 9;
    img {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
      opacity: 0.9;
      // color like in banner popup image background
      background: #f7f8fc;
    }
  }

  &__banner-plus-img {
    z-index: 10;
    position: relative;
    max-height: 15px;
    height: 80%;
  }

  &__plan-wrapper {
    margin: 0 auto;
    margin-top: 70px;
    overflow: hidden;
    min-height: 450px;
    position: relative;
    margin-bottom: 20px;
  }

  &__plan {
    position: relative;

    @media (max-width: 1350px) {
      bottom: 0;
    }
  }

  &__wrap-image {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 52;
    & img {
      max-height: 100%;
    }
  }

  &__control-panel {
    position: absolute;
    right: 10%;
    bottom: 20px;
    z-index: 20;
    @media (max-width: 900px) {
      bottom: 85px;
      right: 10px;
    }
  }

  &__side-panel {
    max-width: 340px;
    margin-top: 16vh;
    padding-bottom: 15px;
    padding-right: 20px;
    overflow: auto;
    margin-right: 7vw;

    @media (max-width: 1250px) {
      margin-right: 4%;
      width: 27%;
    }
  }

  &__audio-panel {
    width: 100%;
    z-index: 54;
    background-color: #fff;
  }

  &__audio-panel-top {
    border: 1px solid #e9ecf5;
    border-radius: 4px 4px 0px 0px;
    padding: 16px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__peoples {
    display: flex;
    margin-bottom: 10px;

    & img {
      max-height: 205px;
      width: auto;
      margin: 0 auto;
      cursor: pointer;
      &:first-child {
        margin-right: 30px;
      }
    }
  }

  &__audio-title {
    margin-bottom: 9px;
    font-weight: 500;
    color: #202531;
    font-size: 18px;
    text-align: center;
  }

  &__audio-button {
    padding: 6px 24px;
    color: #3b99cc;
    border: 1px solid #3b99cc;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    width: fit-content;
    font-weight: 500;
    &:hover {
      background-color: #daf2ff;
    }
    & input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: -1;
    }
  }

  &__audio-uploaded {
    border: 1px solid #e9ecf5;
    border-top: none;
    padding: 24px 16px;
    border-radius: 0px 0px 4px 4px;
    & span {
      margin-bottom: 8px;
      font-size: 13px;
      color: #202531;
      display: block;
      margin-left: 5px;
    }

    & audio {
      width: 100%;
    }
  }

  &__banners {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 53;
  }

  &__banner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__banner-content {
    width: 100%;
    height: 100%;
  }

  &__voice {
    margin-top: 9px;
    & h3 {
      margin-bottom: 10px;
      font-size: 13px;
      color: #3d4453;
      text-align: center;
      font-weight: 400;
    }
  }
  &__voice-choices {
    display: flex;
  }
  &__voice-choice {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #202531;
    margin-right: 32px;
    font-size: 13px;
    & input {
      margin-right: 8px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__banner-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    padding-top: 3px;
    width: 100%;
    & img {
      width: 40px;
      max-width: 30%;
      padding: 0px 3px;
      background-color: #fff;
      cursor: pointer;
      &:hover {
        background-color: rgb(188, 188, 188);
        transition: 0.2s ease-in-out;
      }
    }

    &:hover {
      transition: 0.15s all;
      width: auto;
      z-index: 99;

      & img {
        height: 40px;
        width: auto;
        max-width: none;
      }
    }
  }
}

.model-logo-dropfield {
  width: 100%;
  margin-bottom: 20px;

  &--hidden-input {
    .dropfield {
      &__btn {
        opacity: 0;
      }
    }
  }

  &__title {
    margin-bottom: 7px;
    text-align: center;
    color: #000;
    font-size: 14px;
  }

  .dropfield {
    &__notation {
      color: #000;
    }

    &__main {
      background: transparent;
      & img {
        max-width: 100%;
      }
    }
  }
}
