.landing-footer {
  display: flex;
  justify-content: space-between;
  padding: 48px 31px;
  padding-right: 100px;
  align-items: center;
  background-color: #727e8e;
  position: relative;

  font-size: 13px;
  color: #fff;

  & a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 24px 31px;
    align-items: flex-start;
  }

  &__left-side {
  }

  &__right-side {
  }

  &__bg-icons {
    position: absolute;
    left: 15%;
    top: 15%;
    @media (max-width: 800px) {
      right: 10%;
      left: auto;
    }
  }

  &__bg-icon {
    position: relative;
    &--first {
      left: 70px;
    }
    &--second {
      top: 20px;
    }
    &--third {
      left: 54px;
      top: 13px;
    }
  }
  &__icons {
    display: flex;

    & button {
      background: #727e8e;
      border-radius: 50%;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
