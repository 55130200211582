.documents {
  padding-top: 16px;
  &__cell {
    &--files {
      display: flex;
      align-items: center;
      & img {
        margin-right: 20px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__files-wrapper {
    margin-right: 10px;
    width: 80px;
  }

  &__upload-btn {
    padding: 5px 20px;
    font-size: 14px;
  }
}

.table {
  & tr {
    border-bottom: none;
    & td {
      vertical-align: middle;
      border-bottom: 1px solid #e3e9ee;
    }
  }

  &__print-btn {
    padding: 7px 24px;
  }

  &__head {
    background: #f7f8fc;
    color: #727e8e;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    border-bottom: transparent;
  }
  &__body {
    color: #3f4551;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    &--contacts {
      & tr {
        & td {
          &:last-child {
            border-bottom: 1px solid #e3e9ee;
            border-right: 1px solid #e3e9ee;
          }
        }
      }
    }
  }
  &__status-wait {
    display: flex;
    align-items: center;
    span {
      color: #ff8a00;
      padding: 3px 12px;

      border: 0.5px solid #ff8a00;
      border-radius: 15px;
    }
  }
  &__status-success {
    display: flex;
    align-items: center;
    span {
      color: #27ae60;
      padding: 3px 12px;

      border: 0.5px solid #27ae60;
      border-radius: 15px;
    }
  }
}

@media (max-width: 800px) {
  .documents {
    padding-bottom: 100px;
  }
}

.participant-table {
  & tbody {
    & tr {
      &:hover {
        background-color: rgb(246, 246, 246);
        cursor: pointer;
      }
    }
  }
}
