.no-actual {
  margin: 0 auto;
  margin-top: 126px;
  max-width: 382px;
  &__img {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    display: block;
    margin-bottom: 24px;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #3f4551;
    margin-bottom: 4px;
  }
  &__subtitle {
    font-size: 13px;
    text-align: center;
    color: #727e8e;
    line-height: 16px;
  }
}

@media (max-width: 800px) {
  .no-actual {
    margin-top: 60px;
  }
}

@media (max-width: 575px) {
  .no-actual {
    margin-top: 50px;
  }
}
