.webinar {
  &__nav {
    display: flex;
    border-bottom: 1px solid #e9ecf5;
  }
  &__nav-item {
    flex: 0 1 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: rgba(114, 126, 142, 1);
    padding-bottom: 6px;
    cursor: pointer;
    & span {
      width: 32px;
      height: 22px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      margin-left: 6px;
      border: 1px solid #e9ecf5;
      color: #727e8e;
    }
    &--selected {
      color: rgba(63, 69, 81, 1);
      border-bottom: 1px solid rgba(63, 69, 81, 1);
      & span {
        background: #e9ecf5;
      }
    }
  }

  &__cards {
    display: flex;
    max-width: 847px;
    margin-top: 24px;
    flex-direction: column;
  }
}

.webinar-card {
  padding: 12px 16px;
  border: 1px solid #e9ecf5;
  border-radius: 3px;
  margin-bottom: 17px;
  padding-bottom: 24px;
  &:hover {
    transition: 0.2s;
    background-color: #f7f8fc;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    flex: 1;
    font-size: 18px;
    color: rgba(32, 37, 49, 1);
    word-break: break-all;
    margin-right: 10px;
  }
  &__id {
    font-size: 13px;
    color: rgba(63, 69, 81, 1);
    margin-right: 7px;
  }
  &__date {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(63, 69, 81, 1);
    margin-right: 25px;
    font-size: 13px;
    text-transform: lowercase;
    & span {
      margin-left: 7px;
      & div {
        margin-left: 3px;
        display: inline;
      }
    }
    & img {
      margin-right: 1px;
    }
  }
  &__more {
    position: relative;
  }
  &__more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
    & span {
      font-size: 16px;
    }
    &:hover {
      & span {
        color: #3b99cc;
      }
    }
  }
  &__settings {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;
    right: 0;
    width: 184px;
    padding: 16px;
    background: #fff;
    z-index: 35;
    padding-top: 0;
    box-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1);
  }
  &__settings-item {
    color: rgba(59, 153, 204, 1);
    font-size: 13px;
    padding-bottom: 17px;
    border-bottom: 1px solid #e9ecf5;
    padding-top: 17px;
    display: flex;
    align-items: center;
    font-weight: 500;
    &--danger {
      color: rgba(201, 1, 19, 1);
    }
    & img {
      margin-right: 16px;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &__main {
    margin-top: 8px;
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
  }
  &__type {
    margin-top: 8px;
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
  }
}

.webinar-create {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg {
    height: 100%;
    width: 100%;
    background: #202531;
    opacity: 0.5;
    position: absolute;
    z-index: 10;
  }

  &__wrapper {
    width: 607px;
    padding: 32px;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 11;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    color: rgba(63, 69, 81, 1);
  }
  &__close-btn {
  }
  &__form {
    margin-top: 16px;
    margin-bottom: 20px;
  }
  &__input-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    & label {
      font-size: 13px;
      margin-bottom: 5px;
      color: rgba(32, 37, 49, 1);
    }
    & input {
      padding: 12px 14px;
      border: 1px solid #e9ecf5;
      border-radius: 4px;
      font-size: 13px;
    }
    &--description {
      & textarea {
        padding: 12px 14px;
        border: 1px solid #e9ecf5;
        border-radius: 4px;
        font-size: 13px;
        min-height: 70px;
        resize: none;
      }
    }
    &--error {
      margin-bottom: 8px;
      & input,
      textarea {
        border: 1px solid #c90113;
      }
    }
  }
  &__input-error {
    font-size: 11px;
    color: rgba(201, 1, 19, 1);
  }
  &__footer {
    display: flex;
  }

  &__create-btn {
    margin-right: 32px;
  }

  &__draft-btn {
  }

  &__date-title {
    font-size: 13px;
    margin-bottom: 5px;
    color: rgba(32, 37, 49, 1);
  }

  &__dates {
    display: flex;
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__date-wrapper {
    position: relative;
    min-width: 100px;
    flex: 0 1 160px;
    margin-right: 3%;
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }
    & img {
      position: absolute;
      right: 13px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: -1;
    }
  }
  &__date {
    width: 100%;
    background-color: transparent;
  }
}
