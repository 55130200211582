.landing-how-it-works {
  position: relative;
  padding: 0px 10px;
  margin-top: 130px;
  margin-bottom: 170px;
  @media (max-width: 1070px) {
    margin-bottom: 100px;
    margin-top: 90px;
  }
  @media (max-width: 800px) {
    margin-top: 72px;
  }
  &__main {
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    max-width: 815px;
    justify-content: flex-end;
    position: relative;
    left: 93px;
    @media (max-width: 1000px) {
      left: 0;
    }
    @media (max-width: 800px) {
      margin-top: 23px;
      flex-direction: column;
    }
  }
  &__side-panel {
    margin-right: 40px;
    flex: 0 112px;
    display: flex;
    flex-direction: column;
    @media (max-width: 800px) {
      margin-right: 0;
      flex-direction: row;
      margin-bottom: 24px;
    }
  }
  &__panel-item {
    height: 100px;
    display: flex;
    background: #ffffff;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 6px;
    cursor: pointer;
    @media (max-width: 800px) {
      flex: 30%;
      margin-right: 7px;
      margin-bottom: 0;
      padding: 0px 4px;
      &:last-child {
        margin-right: 0;
      }
    }

    & img {
    }
    & span {
      font-size: 15px;
      margin-top: 5px;
    }
    &--active {
      border: 1px solid #3b99cc;
      & span {
        color: #3b99cc;
      }
    }
  }
  &__text {
    & span {
      font-size: 15px;
      color: #727e8e;
      margin-bottom: 15px;
      display: block;
      @media (max-width: 800px) {
        margin-bottom: 20px;
      }
    }
  }
  &__registration {
    margin-top: 82px;
    @media (max-width: 1070px) {
      margin-top: 40px;
    }
    @media (max-width: 800px) {
      margin-top: 32px;
    }
  }

  &__bg-item {
    position: absolute;
    bottom: -90px;
    opacity: 0.8;
    &--first {
      left: 0;
      @media (max-width: 1070px) {
        left: 50%;
        transform: translate(-50%);
        bottom: -180px;
      }
    }
    &--second {
      right: -18%;
      @media (max-width: 1070px) {
        display: none;
      }
    }
  }
}
