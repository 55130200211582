.stand-settings {
  width: 100%;
  padding: 28px 32px;
  overflow-y: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    &--mailing {
      align-items: center;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-left: 5px;
    margin-top: 7px;
  }

  &__header-btn {
    display: inline-flex;
    margin: 3px 0px;
    margin-left: 10px;
    width: 250px;
    * {
      width: 100%;
    }
  }

  &__about {
    display: flex;
    align-items: flex-start;

    &-back {
      margin-right: 10px;
      margin-top: 7px;
      cursor: pointer;
    }
  }

  &__about--back {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 99;

    cursor: pointer;
  }

  &__title {
    color: #2d2d2d;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #727e8e;
    margin-bottom: 15px;
  }

  &__contacts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
  }

  &__contacts-search {
    flex: 0 1 470px;
    margin-right: 20px;
    border-radius: 4px;
    background-image: url("../../assets/img/eventsSearch.svg");
    background-repeat: no-repeat;
    background-position: right 12px top 50%, 0 0;
  }

  &__contacts-nav {
    display: flex;
    justify-content: flex-end;

    & .active .event-calendar__nav-item {
      opacity: 1;
    }
  }
}

.example {
  &__text {
    color: #727e8e;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    margin-bottom: 10px;
  }
  &__main {
    background: #e9ecf5;
    border-radius: 6px;
  }
  &-nav {
    display: flex;
    padding: 7px 0px 5px 10px;

    &__item {
      width: 8px;
      height: 8px;
      background: #727e8e;
      border-radius: 50%;

      margin-right: 6px;

      cursor: pointer;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;

  &-item {
    border: 1px solid #e9ecf5;
    border-radius: 4px;
    padding: 5px 10px;

    margin-right: 8px;
    cursor: pointer;

    &.active {
      background: #f7f8fc;
      border: 1px solid #3b99cc;
      .pagination-link {
        color: #727e8e;
      }
    }
    &.disabled {
      cursor: not-allowed;
      background: #e9ecf5;
      opacity: 0.5;
    }
  }

  &-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #3f4551;
  }
}

@media (max-width: 800px) {
  .stand-settings {
    width: 90%;
  }
}

@media (max-width: 1100px) {
  .stand-settings {
    padding-bottom: 80px;
  }
}
