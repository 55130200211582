.mailing-header {
  background: #fff;
  padding: 8px 94px 0 94px;
  margin-bottom: 8px;
  justify-content: flex-start;
}

.mailing-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -2px;

  .favorite-nav {
    border: 0;
  }
}

.mailer-inner {
}

.mailingsearch {
  background: white;
  color: #727e8e;
  padding: 12px 14px;
  width: 100%;
  margin-right: 30px;
  border: 1px solid #e9ecf5;
  border-radius: 4px;
}

.mailingsent_inner {
  margin-top: 20px;
}

.mailingsent-table {
  margin-top: 20px;
  border-top: 0;

  thead {
    tr {
      border-top: 0;
    }
  }

  tbody {
    tr {
      background: #ffffff;
    }
  }
}

.create-mailing {
  margin-left: 32px;
  margin-right: 32px;
  min-width: 250px;

  max-height: 50px;
}

.mailing-subheader {
  display: flex;
  justify-content: space-between;
}

.mailing-templates {
  display: flex;
  margin-top: 20px;
  @media (max-width: 900px) {
    padding-bottom: 64px;
  }
}

.mailing-templates-sidebar {
  min-height: calc(100vh - 64px - 56px);
  padding: 15px 25px 0 15px;
  background: #f7f8fc;
  @media (max-width: 795px) {
    min-height: calc(100vh - 100px);
  }

  @media (max-width: 900px) {
    padding-left: 10px;
    max-width: 150px;
  }

  #{&}__list {
    > li {
      padding: 7px 18px;

      &--active {
        > div {
          background: #e9ecf5;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding: 8px 10px;

    transition: all 1s ease-out;

    cursor: pointer;

    &:hover {
      background: #ebecf0;
      transition: all 1s ease-out;
    }
    &.active {
      background: #e9ecf5;
    }
  }

  &__name {
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  & .active &__name {
    color: #202531;
  }
}

.mailing-templates-redactor {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mailing-templates-redactor-head {
  display: flex;
  justify-content: space-between;
  padding: 14px 32px;

  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    color: #727e8e;
  }
}

.mailing-template-action {
  margin-left: 16px;

  &--send {
    margin-left: 24px;
  }
}

.mailing-templates-redactor-working-area {
  flex: 1;
  background: #82848a;
  display: flex;
  justify-content: center;
  padding: 24px;

  &__iframe {
    display: flex;
    width: 100%;
    .row {
      margin: 0;
      display: block;
    }

    & iframe {
      min-width: auto !important;
    }
  }
}

.mailing-edit-btn {
  background: transparent;
  border: 1px solid #e9ecf5;
  padding: 10px 15px;
  border-radius: 4px;

  img {
    width: 16px;
    height: 16px;
  }
}

.mailingTooltip {
  margin-left: 15px;
}

.mailing-edit-btn:hover {
  background: #ffffff;
}

.mailing_input {
  display: block;
  width: 100%;
}

.tag-wrapper {
  display: inline-block;
  margin-bottom: 5px;
  color: #727e8e;
  background: #e9ecf5;
  border-radius: 15px;
  padding: 5px 10px;
  margin-right: 5px;
  font-weight: 400;

  .ReactTags__remove {
    color: #727e8e;
    margin-left: 6px;
    font-size: 16px;
  }
}

.react-tags-wrapper {
  display: block;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 15px 14px;
  color: #3f4551;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  background-color: #fff;
}

.ReactTags__tagInputField {
  display: inline-block;
  width: 100%;
}

.sendMailingForm {
  input {
    margin-bottom: 25px;
  }

  textarea {
    margin-bottom: 16px;
  }
}

.newTemplate {
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3b99cc;
  width: 100%;
  img {
    margin-right: 8px;
  }
}

.mailing-helper-text {
  margin-top: 5px;
  color: #727e8e;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.newTemplate:hover {
  background: transparent;
  color: #3b99cc;
}
.contactsimportlabel {
  cursor: pointer;
  display: inline-block;
  max-width: 600px;
  color: #3b99cc;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  .contactsimport {
    display: none;
  }
  &:hover {
    text-decoration: underline;
  }
}
.mailingwrapper {
  display: flex;
  width: 100%;
  .mailingwrapperinner {
    width: 100%;
  }
}
