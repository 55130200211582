.landing-perspective {
  padding: 0px 30px;
  padding-top: 82px;
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 100px;

  @media (max-width: 800px) {
    padding: 0px 10px;
    padding-top: 0;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 50%;
    top: 19%;
    transform: translate(-50%);
    @media (max-width: 800px) {
      width: 100%;
      top: 30%;
    }
  }

  &__main {
    background: #e5f0f9;
    border-radius: 4px;
    max-width: 1308px;
    margin: 0 auto;
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    @media (max-width: 800px) {
      flex-direction: column;
      padding: 60px 44px 50px 44px;
    }
  }
  &__image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 100px;
    & img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 800px) {
      margin-right: 0;
      margin-bottom: 34px;
    }
  }
  &__text {
    max-width: 390px;
    position: relative;
    & h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #3f4551;
      margin-bottom: 10px;
      @media (max-width: 800px) {
        font-size: 20px;
      }
    }
    & span {
      font-size: 15px;
      font-style: italic;
      @media (max-width: 800px) {
        font-size: 13px;
      }
    }
  }

  &__commas {
    position: absolute;

    @media (max-width: 800px) {
      height: 30px;
    }

    &--first {
      transform: rotate(180deg);
      left: -67px;
      top: -10px;
      @media (max-width: 800px) {
        left: 0;
        top: -34px;
        height: 30px;
      }
    }
    &--second {
      right: -45px;
      bottom: -5px;
      @media (max-width: 800px) {
        right: 0;
      }
    }
  }
}
