.model-popup {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 666;
  top: 0;
  left: 0;

  &__desc-title {
    margin-top: 10px;
    display: flex;
    align-items: center;
    & span {
      margin-left: 5px;
      font-size: 14px;
    }
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__bg {
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: #202531;
    opacity: 0.5;
    top: 0;
    left: 0;
    position: fixed;
  }
  &__main {
    z-index: 480;
    background: #fff;
    width: 660px;
    padding: 32px;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
    & h3 {
      color: #3f4551;
      font-size: 24px;
    }
    & button {
      & img {
        height: 12px;
        width: 12px;
      }
    }
  }
  &__file {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    background: #f7f8fc;
    border: 1px dashed #3b99cc;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    height: auto;
    min-height: 170px;
    max-height: 300px;
    display: flex;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    cursor: pointer;
    user-select: none;

    & span {
      margin-left: 5px;
      font-size: 14px;
      color: #000;
    }
  }

  &__description-input {
    margin-top: 5px;
  }

  &__wrap-file-img {
    max-height: 100%;
    display: inline-flex;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1;

    & img {
      height: 100%;
      max-width: 100%;
      min-width: 40%;
      z-index: 44;
    }
  }

  &__crop-wrapper {
    max-height: 100%;
    display: inline-flex;
    margin: 0 auto;
  }

  &__crop {
    z-index: 56;
    margin: 0 auto;
    height: 100%;
    > div:first-child {
      height: 100%;
      margin: 0 auto;
    }
  }

  &__file-main {
    position: absolute;
    height: 100%;
    z-index: 5;
    width: 100%;
    padding: 10px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__file-input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 55;
  }

  &__file-add-img {
    background-color: #fff;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    padding: 3px;
    margin-bottom: 17px;
  }
  &__file-text {
    text-align: center;
    & h3 {
      color: #3b99cc;
      font-size: 13px;
    }
    & span {
      color: #838d9c;
      font-size: 13px;
    }
  }
  &__posttitle {
    font-size: 13px;
    color: #727e8e;
    & h3 {
      font-size: 13px;
    }
  }

  &__buttons {
    display: flex;
    align-self: flex-end;
    margin-top: 27px;
    & button {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__footer-title {
    color: rgba(32, 37, 49, 1);
    font-size: 13px;
    margin-bottom: 5px;
  }
  &__footer-input {
    width: 100%;
    padding: 12px 14px;
    font-size: 13px;
    border: 1px solid #e9ecf5;
    border-radius: 4px;
    margin-bottom: 40px;
    max-width: 500px;
    &:focus {
      border: 1px solid #3b99cc;
    }
  }
}

.popup-tabs {
  &__headers {
    display: flex;
    align-items: flex-end;
  }
  &__header {
    padding: 5px 10px;
    border-top: 1px solid rgb(175, 175, 175);
    border-right: 1px solid rgb(175, 175, 175);
    color: rgb(136, 136, 136);
    background: rgb(243, 243, 243);
    font-size: 14px;
    cursor: pointer;
    &:first-child {
      border-top-left-radius: 10px;
      border-left: 1px solid rgb(175, 175, 175);
    }
    &--active {
      font-size: 16px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 8px 15px;
      background: #c90113;
      border-color: #c90113 !important;
      color: #fff;
    }
    &:last-child {
      border-top-right-radius: 10px;
    }
  }
  &__tabs {
    border: 1px solid rgb(211, 211, 211);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    &--no-border {
      border: none;
      & .popup-tabs__tab {
        padding: 0;
      }
    }
  }
  &__wrap-frame {
    width: 100%;
    margin-top: 10px;
  }
  &__frame {
    width: 100%;
  }
  &__tab {
    padding: 20px;
    display: none;
    &--active {
      display: block;
    }
  }
}

.video-frame {
  position: relative;
  &__input {
    width: 100%;
    margin-top: 0;
    padding-right: 140px;
  }
  &__btn {
    position: absolute;
    right: 8px;
    background: #c90113;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      background: #80000b;
      color: #fff;
    }
  }
}
